@import '../../../../constants.less';

#our-story {
    background-color: @green42;

    div.container {
        align-items: center;
    }

    div.story-container {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        margin-top: 30px;

        .img-container {
            width: 50%;
            padding: 20px;

            img {
                width: 100%;
                border-radius: 16px;
            }
        }

        .text-container {
            width: 50%;
            padding-left: 60px;
            text-align: left;
        
            p.meet-team {
                text-decoration: underline;
                text-decoration-thickness: 1px;
            }
        }
    }
}

@media screen and (max-width: @mobile-design) {
    #our-story > div.container > div.story-container {
        .img-container {
            width: 100%;
            padding: 0;
        }

        .text-container {
            width: 100%;
            padding: 0;
            text-align: justify;

            h3 {
                margin-top: 40px;
                text-align: center;
            }
        }
    }
}