@import "./constants.less";

@font-face {
    font-family: "Poppins-Regular";
    src: url("fonts/Poppins-Medium.ttf");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "Poppins-Regular-Italic";
    src: url("fonts/Poppins-MediumItalic.ttf");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "Poppins-Light";
    src: url("fonts/Poppins-Light.ttf");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "Poppins-Light-Italic";
    src: url("fonts/Poppins-LightItalic.ttf");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "Poppins-Bold";
    src: url("fonts/Poppins-Bold.ttf");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "Poppins-Bold-Italic";
    src: url("fonts/Poppins-BoldItalic.ttf");
    font-weight: normal;
    font-style: normal;
}

.row {
    display: flex;
    flex-direction: row;
}

body {
    font-family: Poppins-Light, sans-serif;
    overflow: hidden;
    margin: 0px;
}

h1,
h2,
h3,
h4,
h5 {
    font-family: Poppins-Bold, sans-serif;
}

h1 {
    font-size: 80px;
    line-height: 100%;
}

h2 {
    font-size: 40px;
}

h3 {
    font-size: 26px;
}

.no-click {
    pointer-events: none;
    cursor: default !important;
}

a.mail-link {
    text-decoration: underline;
    color: inherit;
}

a.no-underline {
    text-decoration: none;
}

p {
    text-align: justify;
}

// Tooltip code from: https://www.w3schools.com/css/css_tooltip.asp

/* Tooltip container */
.tooltip-wrapper {
    position: relative;
    display: inline-block;
    width: fit-content;
}

/* Tooltip text */
.tooltip-wrapper .tooltip {
    visibility: hidden;
    width: 240px;
    bottom: 80%;
    left: 50%;
    margin-left: -120px;
    background-color: #292b2d;
    color: white;
    text-align: center;
    padding: 5px 10px;
    border-radius: 6px;
    border: 1px solid white;

    /* Position the tooltip text - see examples below! */
    position: absolute;
    z-index: 1;
}

/* Show the tooltip text when you mouse over the tooltip container */
.tooltip-wrapper:hover .tooltip,
.tooltip.force-visible {
    visibility: visible;
}

/* Tooltip arrow */
.tooltip-wrapper .tooltip::after {
    content: " ";
    position: absolute;
    top: 100%; /* At the bottom of the tooltip */
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: white transparent transparent transparent;
}

.tooltip-wrapper.light .tooltip {
    background-color: @white42;
    color: @black42;
    border-color: @black42 !important;
}

.tooltip-wrapper.light .tooltip::after {
    border-color: @black42 transparent transparent transparent;
}

@media screen and (max-width: @mobile-design) {
    h1 {
        font-size: 36px;
        margin-top: 0px;
    }

    h2 {
        font-size: 26px;
    }

    h3 {
        font-size: 19px;
    }

    .tooltip-wrapper .tooltip {
        width: 180px;
        margin-left: -90px;
    }
}

@media screen and (min-width: @mobile-design) {
    body,
    #scrollable {
        overflow: overlay !important;
        background-color: rgba(0, 0, 0, 0);
    }

    #scrollable::-webkit-scrollbar {
        width: 8px; /* width of the entire scrollbar */
        background-color: rgba(0, 0, 0, 0);
    }

    #scrollable::-webkit-scrollbar-thumb {
        background-color: @white42; /* color of the scroll thumb */
        border-radius: 16px; /* roundness of the scroll thumb */
    }

    #scrollable {
        scrollbar-width: thin; /* "auto" or "thin" */
        scrollbar-color: grey @white42; /* scroll thumb and track */
    }
}
