@import "../../constants.less";

@animation-transform-duration: 0.8s;
@animation-expand-duration: 0.3s;

#menu-button {
    display: inline-flex;
    flex-direction: column;
    align-items: flex-end;
    cursor: pointer;
    width: 30px;
}

.bar1,
.bar2,
.bar3 {
    width: 5px;
    height: 5px;
    margin: 0 0 6px;
    border-radius: 5px;
}

.bar1 {
    background-color: @red42;
    transition: width @animation-expand-duration, transform @animation-transform-duration;
}

.bar2 {
    background-color: @green42;
    transition: width @animation-expand-duration linear (@animation-expand-duration / 2),
        opacity @animation-transform-duration;
}

.bar3 {
    background-color: @yellow42;
    transition: width @animation-expand-duration linear @animation-expand-duration,
        transform @animation-transform-duration, background-color @animation-transform-duration;
}

.change {
    & > div {
        width: 30px !important;
    }

    .bar1 {
        -webkit-transform: rotate(-45deg) translate(-8.5px, 7px);
        transform: rotate(-45deg) translate(-8.5px, 7px);
    }

    .bar2 {
        opacity: 0;
    }

    .bar3 {
        -webkit-transform: rotate(45deg) translate(-8.25px, -7px);
        transform: rotate(45deg) translate(-8.25px, -7px);
        background-color: @red42;
    }
}

.expanded-menu-button() {
    & > .bar1 {
        width: 16px;
    }
    & > .bar2 {
        width: 23px;
    }
    & > .bar3 {
        width: 30px;
    }
}

@media screen and (max-width: @mobile-design) {
    .scroll-up #menu-button {
        .expanded-menu-button();
    }
}

@media screen and (min-width: @mobile-design + 1) {
    #menu-button:hover {
        .expanded-menu-button();
    }
}
