@import "../../constants.less";

.aside-right {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 35px;
    margin-right: 35px;
}

@media screen and (max-width: @mobile-design) {
    .aside-right {
        margin-top: 27px;
        margin-right: 5px;
    }
}