@import "../../constants.less";

#contact {
    background-color: @black42;
    padding: 50px @right-aside-width 80px @left-aside-width;

    .container {
        color: white;
        flex-direction: row;
        // -50px and -80px are because of padding a few lines above.
        min-height: calc(100vh - 50px - 80px);
        flex-wrap: wrap;
        position: relative;

        #welcome {
            width: 50%;
            padding-bottom: 0px;

            @media screen and (max-width: @mobile-design) {
                h1 {
                    font-size: 42px;
                }
            }
        }

        #info {
            width: 50%;
            padding-top: 70px;
            padding-left: 50px;

            input,
            textarea {
                box-sizing: border-box;
                width: 100%;
                padding: 12px;
                font-size: 16px;
                background-color: inherit;
                color: white;
                border: none;
                border-bottom: 1px solid oldlace;
                font-family: Poppins-Regular, sans-serif;
                resize: none;
            }

            input {
                margin-bottom: 10px;
            }

            textarea:focus,
            input:focus {
                outline: none;
            }

            #send-btn {
                display: flex;
                justify-content: flex-end;

                .tooltip-wrapper {
                    margin-left: auto;

                    h2 {
                        cursor: pointer;
                        width: fit-content;

                        &.disabled {
                            cursor: none;
                            pointer-events: none;
                            color: @grey-text;
                        }
                    }
                }
            }
        }

        #what-next {
            h3 {
                margin-top: 0px;
            }
        }

        #additional-info-bar {
            position: absolute;
            bottom: 0;
            right: 0;
            width: fit-content;
            display: flex;
            flex-wrap: wrap;
            align-items: flex-end;

            p {
                box-sizing: border-box;
                padding: 10px 0;
                margin: 0 40px;
                text-align: center;
                font-size: 14px;

                &:hover {
                    text-decoration: none;
                    border-top: 1px solid white;
                }
            }

            p:last-child {
                margin-right: 0;
            }

            hr {
                width: 120px;
                margin-left: 0;
                margin-right: auto;
            }

            @media screen and (min-width: @mobile-design) {
                .line-wrap {
                    display: none;
                }
            }
        }

        @media screen and (max-width: @mobile-design) {
            #welcome,
            #info {
                width: 100%;
                padding: 20px;

                & > p {
                    width: 70%;
                }
            }

            #additional-info-bar {
                bottom: 0;

                p {
                    width: 100%;
                    padding: 0%;
                    margin: 4px;
                    text-align: left;

                    &:hover {
                        border-top: none;
                    }
                }

                hr {
                    width: 120px;
                    margin-right: auto;
                }
            }
        }
    }
}

@media screen and (max-width: @mobile-design) {
    #contact {
        padding: 80px @mobile-aside-right 80px @mobile-aside-left;

        > .container {
            padding-bottom: 150px;
        }
    }
}
