@import "../../constants.less";

footer {
    background-color: @black42;
    padding-bottom: 30px;

    hr {
        margin: 5px 60px 80px;
        border: none;
        border-top: 1px solid @grey-text;
        background-color: @grey-text;
    }

    a,
    p {
        text-decoration: none;
        color: @grey-text;
    }

    .disclamer-container {
        margin-left: 200px;

        a,
        p {
            font-family: Poppins-Regular, sans-serif;
            font-size: smaller;
            margin: 0;
            margin-right: 15px;
        }
    }
}

#footer-section {
    min-height: calc(100vh - 160px) !important;

    #menu-links {
        width: fit-content;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 0 80px;
        margin: 60px 0;
    }

    .container {
        align-items: flex-start;

        #social-links {
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: flex-end;

            #social-media,
            #newsletter {
                display: flex;
                flex-direction: column;
            }

            h2 {
                margin: 10px;
            }

            a {
                margin-top: 25px;
            }

            #newsletter {
                p {
                    color: white;
                }

                .input-wrapper {
                    position: relative;
                    border-bottom: @white42 1px solid;

                    input {
                        width: 250px;
                        padding: 4px 0;
                        border: none;
                        color: @white42;
                        background-color: @black42;
                        font-family: Poppins-Light, sans-serif;

                        &:focus {
                            outline: none;
                        }
                    }

                    img {
                        position: absolute;
                        right: 0;
                        bottom: 4px;
                        cursor: pointer;
                    }
                }
            }
        }

        h2 {
            cursor: pointer;
            margin-top: 25px;
        }

        .red {
            color: @red42;
        }

        .green {
            color: @green42;
        }

        .yellow {
            color: @yellow42;
        }
    }
}

@media screen and (max-width: @mobile-design) {
    #footer-section {
        padding-top: 40px;
        padding-bottom: 20px;
        min-height: 0 !important;

        .container {
            margin-top: 0;
            align-items: center;

            #menu-links,
            #social-links {
                width: 90%;

                & h2 {
                    font-size: 30px;
                }
            }

            #menu-links {
                gap: unset;
                margin: 16px 0;
            }

            #social-links {
                flex-direction: column;
                align-items: flex-start;

                #newsletter {
                    width: 100%;
                    margin: 24px 0;

                    .input-wrapper > input {
                        width: 100%;
                    }
                }
            }
        }
    }

    footer {
        hr {
            margin: 5px 20px 20px;
        }

        .disclamer-container {
            margin-left: @mobile-aside-left;
        }
    }
}

@media screen and (max-width: @mobile-design) and (orientation: landscape) {
    #footer-section {
        overflow: visible;

        & > .fixed-holder:not(.top) {
            clip-path: inset(0px 0px -30px 0px);
            -webkit-clip-path: inset(0px 0px -30px 0px);
        }
    }
}
