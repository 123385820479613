@import "../../../../constants.less";

#team {
    background-color: @green42;

    & > div.container {
        align-items: center;
    }

    p {
        text-align: justify;
    }

    #team-images {
        margin: 30px -20px 0;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: center;

        img {
            box-sizing: border-box;
            padding: 20px;
            max-width: 100%;
            border-radius: 40px;
        }

        img.half-width-desktop {
            max-width: 50%;
        }

        p {
            margin-left: auto;
            margin-right: auto;
        }
    }

    #team-intro {
        margin-top: 80px;
    }
    #team-icons {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 50px 200px;
        margin: 50px 0;

        div {
            display: flex;
            flex-direction: column;
            align-items: center;
            margin: 30px;

            > img {
                height: 200px;
            }

            > div.team-titles {
                position: relative;
                width: fit-content;
                text-align: left !important;
                margin: 20px 0;

                > h3,
                p {
                    margin: 0;
                }

                > p {
                    position: absolute;
                    left: 0;
                    top: 32px;
                    width: 200px;
                    overflow: visible;
                    text-align: left;
                }
            }
        }
    }
}

@media screen and (max-width: @mobile-design) {
    #team {
        #team-images {
            margin: 0;
            width: 100%;

            img {
                box-sizing: content-box;
                padding: 0;
                margin: 15px 0;
                border-radius: 20px;
            }

            img.half-width-desktop {
                max-width: 100%;
            }
        }

        #team-icons {
            width: 100%;
            gap: 60px 20px;

            div {
                margin: 0px;

                > img {
                    height: 120px;
                }

                > div.team-titles {
                    > p {
                        top: 25px;
                        width: 150px;
                    }
                }
            }
        }
    }
}
