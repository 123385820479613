@import "../../constants.less";

#layout {
    #scrollable {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        overflow: auto;
    }

    .aside {
        position: fixed;
        top: 0;
        z-index: 10;

        &-left {
            left: 0;
            width: @left-aside-width;
        }

        &-right {
            right: 0;
            width: @right-aside-width;
        }
    }

    div {
        box-sizing: border-box;
    }

    #contact-container,
    nav {
        position: fixed;
        top: 0;
        left: 100%;
        width: 100%;
        z-index: 5;
        transition: left @menuOpen;
    }

    &.menu-open {
        nav {
            left: 0;
        }
    }

    #contact-container {
        overflow: auto;
        height: 100vh;
    }

    &.contact-open {
        #contact-container {
            left: 0;
        }
    }
}

@media screen and (max-width: @mobile-design) {
    #layout {
        .scroll-hide {
            transition: transform @scrollHide;
        }

        &.scroll-down {
            .scroll-hide {
                transform: translate(0, -80px);
            }
        }

        .aside {
            &-left {
                width: @mobile-aside-left;
            }

            &-right {
                width: @mobile-aside-right;
            }
        }
    }
}
