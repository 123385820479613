@import "../../../../constants.less";

#about {
    background-color: @red42;

    div.container {
        align-items: center;

        a {
            color: inherit !important;
            text-decoration: none;
        }

        h1 {
            text-align: center;
        }

        p {
            text-align: justify;
        }

        div.items-container {
            display: flex;
            flex-wrap: wrap;

            div.item {
                width: 50%;
                text-align: center;
                padding: 50px;

                & > img {
                    margin: auto;
                    width: fit-content;
                }
            }
        }
    }
}

@media screen and (max-width: @mobile-design) {
    #about {
        div.container {
            div.items-container {
                div.item {
                    margin-top: 30px;
                    width: 100%;
                    padding: 0;
                }
            }
        }

        #team-images {
            width: 100%;

            img.half-width-desktop {
                max-width: 100%;
            }
        }
    }
}
