@import "../../../../constants.less";

#quotes {
    background-color: @yellow42;

    h1 {
        text-align: center;
    }

    div.container {
        align-items: center;

        .quotes-carousel {
            width: 100%;

            ul.control-dots {
                text-align: right;

                .dot {
                    height: 12px;
                    width: 12px;
                    border: 1px solid @white42;
                    border-radius: 50%;
                    background-color: @yellow42;
                    opacity: 1;
                    box-shadow: none;

                    &.selected {
                        background-color: @white42;
                    }
                }
            }
        }

        p {
            width: 85%;
        }
    }

    div.quote {
        text-align: left;

        div.quote-row {
            display: flex;
            flex-wrap: wrap;
            margin: 40px 0;

            img {
                width: 20%;
                padding-right: 30px;
            }

            p {
                width: 80%;
                margin: 0;
                font-size: x-large;
            }
        }

        .quote-signature {
            padding-left: 7%;

            div.quote-name {
                font-weight: bold;
            }
        }
    }
}

@media screen and (max-width: @mobile-design) {
    #quotes {
        div.container {
            p {
                width: 100%;
            }

            .quotes-carousel {
                width: 90%;

                ul.control-dots {
                    text-align: left;

                    .dot {
                        margin-right: 40px;
                        width: 24px;
                        height: 24px;
                    }
                }
            }

            div.quote {
                div.quote-row {
                    img {
                        width: 30%;
                        padding-right: 30px;
                        margin-bottom: 40px;
                    }

                    p {
                        width: 100%;
                    }
                }

                .quote-signature {
                    padding-left: 0%;
                    margin-bottom: 100px;
                }
            }
        }
    }
}
