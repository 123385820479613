@import "../../constants.less";

#services {
    font-family: Poppins-Light !important;

    #services-home {
        background-color: @white42;
        justify-content: center;
    }

    #services-list {
        background-color: @red42;

        h1 {
            text-align: center;
        }

        a {
            color: inherit;
            text-decoration: none;
        }

        .item {
            display: flex;
            justify-content: space-evenly;
            align-items: center;
            text-align: left;
            margin: 30px 0;

            img {
                max-height: 120px;
                object-fit: contain;
            }

            h3 {
                margin: 30px 40px;
            }

            > * {
                flex: 1;
            }
        }
    }

    #services-detailed {
        background-color: @green42;

        .service {
            border-bottom: 1px @white42 solid;
            margin: 20px 0;
            padding: 10px 0;

            .title-container {
                display: flex;
                align-items: center;

                .name-container {
                    h3 {
                        width: fit-content;
                        margin: 0 auto;
                    }

                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    min-width: 35%;
                }

                p {
                    margin-bottom: auto;
                }

                img {
                    max-height: 60px;
                    object-fit: contain;
                }

                > * {
                    margin: 20px;
                }
            }

            .content {
                display: flex;
                margin: 50px 0;

                .services-list {
                    // Dark magic part 1
                    // This aligns service description with second part of title on desktop (first part of service name is 35%)
                    // + additionally a total of 5 margins, 20px each... + 15 pixels for index number of service
                    min-width: calc(35% + (5 * 20px) + 15px);
                    font-family: "Poppins-Bold";
                    padding: 0 75px;

                    > p {
                        padding-bottom: 10px;
                        border-bottom: 1px @white42 solid;
                        max-width: 200px;
                    }
                }

                .services-description {
                    // Dark magic part 2
                    min-width: calc(65% - (5 * 20px) - 15px);
                    margin: 0;

                    h3 {
                        margin-top: 0px;
                    }

                    svg {
                        overflow: visible;
                    }
                }
            }
        }

        .service:last-child {
            border-bottom: none;
        }
    }
}
@media screen and (max-width: @mobile-design) {
    #services {
        #services-list .item {
            flex-direction: column;
            padding: 20px;

            h3 {
                width: fit-content;
                margin: 30px auto;
            }

            ul {
                width: 90%;
            }
        }

        #services-detailed .service {
            h3 {
                font-size: 24px;
            }

            .title-container {
                flex-direction: column;
                align-items: flex-start;

                .name-container {
                    min-width: 75%;
                    width: fit-content;

                    h3 {
                        min-width: fit-content;
                        margin: 0 auto;
                        padding: 0 10px;
                    }
                }

                img {
                    max-height: 50px;
                }

                > * {
                    margin: 10px 0;
                }
            }

            .content {
                flex-wrap: wrap;
                margin: 10px 0;

                .services-list {
                    width: 50%;
                    padding: unset;
                }

                .services-description {
                    width: 100%;
                    margin: 20px 0;

                    h3 {
                        text-align: center;
                    }

                    img {
                        margin: 20px 0;
                    }
                }
            }
        }
    }
}
