@import "../constants.less";

@animationDuration: 100ms;

.project-42-section {
    box-sizing: border-box;
    width: 100%;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 60px @right-aside-width 100px @left-aside-width;
    position: relative;
    overflow: hidden;

    .white {
        color: white;

        .part-of-uprise {
            color: white !important;
        }
    }

    .grey {
        color: @grey-text;
        .part-of-uprise {
            color: @grey-text !important;
        }
    }

    & > div.container {
        display: flex;
        flex-direction: column;
        max-width: 1000px;
        width: 100%;
        z-index: 2;
    }

    & > .fixed-holder {
        position: absolute;
        left: 0%;
        top: 0%;
        right: 0%;
        bottom: 0%;
        clip-path: inset(0px 0px 0px 0px);
        -webkit-clip-path: inset(0px 0px 0px 0px);
        z-index: 1;

        &.top {
            z-index: 4;
            right: calc(100vw - @left-aside-width);
        }

        & > * {
            position: fixed;
            user-select: none;
        }

        .logo {
            width: 70px;
            top: 35px;
            left: 35px;
            right: 0px;
            animation: repaint @animationDuration infinite;

            .mobile-logo {
                display: none;
            }
        }

        .part-of-uprise {
            font-family: Poppins-Regular, sans-serif;
            font-size: smaller;
            writing-mode: vertical-rl;
            transform: rotate(180deg);
            top: 50%;
            left: 35px;
            margin: 0px;
            animation: repaint @animationDuration infinite;
        }

        .contact-holder {
            display: flex;
            font-family: Poppins-Regular, sans-serif;
            font-size: smaller;
            writing-mode: vertical-rl;
            transform: rotate(180deg);
            cursor: pointer;
            bottom: 38vh;
            right: 40px;
            z-index: 10;
            animation: repaint @animationDuration infinite;

            p {
                white-space: nowrap;
                margin: 0;
                margin-top: 15px;
            }

            svg {
                width: 20px;
                height: 20px;
            }

            &.green {
                color: @green42;
            }

            &.red {
                color: @red42;
            }

            &.yellow {
                color: @yellow42;
            }
        }
    }
}

@media screen and (max-width: @mobile-design) {
    .project-42-section {
        padding: 80px @mobile-aside-right 80px @mobile-aside-left;

        & > .fixed-holder {
            &.top {
                z-index: 4;
                right: @right-aside-width;
                bottom: calc(100vh - 80px);
                clip-path: inset(0px 0px calc(calc(100vh - 80px) * -1) 0px);
                -webkit-clip-path: inset(0px 0px calc(calc(100vh - 80px) * -1) 0px);
                pointer-events: none;

                .mobile-logo {
                    pointer-events: visible;
                }
            }

            .logo {
                top: 15px;
                left: 15px;

                .desktop-logo {
                    display: none;
                }

                .mobile-logo {
                    display: block;
                    min-width: 180px;
                    object-fit: contain;
                }
            }

            .part-of-uprise {
                display: none;
            }

            .contact-holder {
                right: 10px;
                bottom: 33vh;
            }
        }
    }
}

@keyframes repaint {
    from {
        z-index: 1000;
    }
    to {
        z-index: 1200;
    }
}
