@import "../../constants.less";

.legal-section {
    background-color: @white42;
    justify-content: center;
    color: @grey-text;

    p,
    li {
        text-align: justify;
    }

    b {
        margin: 16px 0;
        font-family: Poppins-Bold;
    }
}

#legal {
    p:not(.part-of-uprise) {
        margin: 8px 0px;
    }
}
