@import "../../constants.less";

.project {
    display: flex;
    flex-wrap: wrap;
    margin: 80px 0;
    align-items: center;

    .project-summary {
        width: 50%;
        padding-right: 150px;
        line-height: 1.2;
    }

    a {
        width: 50%;
        
        & > img {
            width: 100%;
            border-radius: 15px;
            object-fit: contain;
        }
    }
}

@media screen and (max-width: @mobile-design) {
    .project {
        margin: 30px 0;

        p.project-description {
            display: none;
        }

        .project-summary {
            width: 100%;
            padding: 0px;

            p {
                margin: 0;
                padding: 0;
            }
        }

        a {
            width: 100%;
        }

        h2 {
            padding-left: 30px;
            text-align: left;
            margin-top: 0px;
        }
    }
}