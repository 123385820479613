@import "../../../../constants.less";

#team-home {
    background-color: @white42;
    justify-content: center;

    p {
        text-align: justify;
    }
}
