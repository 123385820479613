@import "../../constants.less";

#cookie-wrapper {
    width: 100%;
    display: flex;
    height: fit-content;
    justify-content: center;
    position: fixed;
    bottom: 0;
    z-index: 1000;

    .cookie-banner {
        max-width: 100%;
        width: 1000px;
        background-color: #586063 !important;
        opacity: 0.85;
        z-index: 999;
        padding: 16px 32px;
        border-radius: 8px;
        display: flex;
        justify-content: space-between;

        .cookie-content {
            display: flex;
            align-items: center;
            text-align: justify;
            color: white;
        }

        .cookie-buttons {
            display: flex;
            flex-direction: row-reverse;

            button {
                margin: 0 10px;
                background-color: inherit;
                border: none;
                color: white;
                font-family: Poppins-Bold;
                font-size: x-large;
                cursor: pointer;
            }
        }
    }
}

@media screen and (max-width: @mobile-design) {
    #cookie-wrapper {
        .cookie-banner {
            opacity: 1;
            padding: 10px 16px;
            display: flex;
            flex-direction: column;
            border-radius: 0;

            .cookie-buttons {
                display: flex;
                justify-content: center;
                text-align: left;

                button {
                    margin: 10px 15px;
                }
            }
        }
    }
}
