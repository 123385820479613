@import "../../../../constants.less";

#home {
    background-color: @white42;
    justify-content: center;

    p {
        text-align: justify;
    }

    .container {
        span,
        p,
        a {
            color: @grey-home-wizard;
        }

        h1 {
            text-align: left;
        }

        span {
            margin-top: 16px;
            padding-left: 50px;
            padding-right: 5px;
        }

        a {
            font-family: Poppins-Bold, sans-serif;
            text-decoration: underline;
            text-decoration-thickness: 2px;
        }

        a.non-underlined {
            text-decoration: none;
        }

        .row > div {
            width: 65%;
        }

        .wizard-container {
            width: 65%;
            margin-left: 50px;
            margin-top: 16px;
            display: flex;
            justify-content: center;
            overflow: hidden;
        }
    }
}

@media screen and (max-width: @mobile-design) {
    #home {
        .container {
            span {
                margin-top: 16px;
                padding-left: 5px;
                padding-right: 10px;
            }

            .row > div {
                width: 100%;
            }

            .wizard-container {
                margin-top: 0;
                width: 100%;
                margin-left: 0;
            }
        }
    }
}
