@import "../../constants.less";


#work {
    h1 {
        text-align: center;
    }

    #work-home {
        background-color: @white42;
        justify-content: center;    
        
        h1 {
            text-align: left;
        }
    }
    
    #project-list {
        background-color: @red42;
        justify-content: center;
    }
}