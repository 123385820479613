@import '../../../../constants.less';

#about-us {
    background-color: @red42;

    div.container {
        align-items: center;
        
        p {
            text-align: justify;
            margin-bottom: 40px;
        }

        img {
            width: 100%;
            margin: 20px;
            border-radius: 16px;
            margin: 16px 0px;
        }

        .about-row {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            
            div.img-container {
                width: 50%;
                padding: 20px;
            }

            .text-description {
                padding-right: 100px;
                width: 50%;
            }

            &.reverse {
                flex-direction: row-reverse;
            
                .text-description {
                    padding-left: 100px;
                    padding-right: 0px;
                }
            }
        }
    }
}

@media screen and (max-width: @mobile-design) {
    #about-us {
        div.container {
            p {
                width: 100%;
            }

            div.about-row {

                .img-container {
                    width: 100%;
                }

                .text-description {
                    width: 100%;
                    padding: 0px !important;
                    text-align: justify;

                    h3 {
                        text-align: center;
                    }

                    p {
                        width: 100%;
                    }
                }
            }

        }
    }
}