@import "../../constants.less";

.toast-wrapper {
    position: fixed; /* Sit on top of the screen */
    z-index: 999; /* Add a z-index if needed */
    width: 100vw;
    display: flex;
    justify-content: center;
    bottom: 30px; /* 30px from the bottom */

    // Entire code heavily inspired by: https://www.w3schools.com/howto/howto_js_snackbar.asp
    .toast {
        visibility: hidden; /* Hidden by default. Visible on click */
        min-width: 300px; /* Set a default minimum width */
        padding: 10px 16px; /* Padding */
        background-color: #586063; /* Black background color */
        color: @white42; /* White text color */
        text-align: center; /* Centered text */
        border-radius: 6px; /* Rounded borders */
        white-space: pre;

        &.show {
            visibility: visible; /* Show the snackbar */
            /* Add animation: Take 0.5 seconds to fade in and out the snackbar.
            However, delay the fade out process for 2.5 seconds */
            -webkit-animation: fadein 0.5s, fadeout 0.5s 4.5s;
            animation: fadein 0.5s, fadeout 0.5s 4.5s;
        }
    }
}

/* Animations to fade the snackbar in and out */
@-webkit-keyframes fadein {
    from {
        bottom: 0;
        opacity: 0;
    }
    to {
        bottom: 30px;
        opacity: 1;
    }
}

@keyframes fadein {
    from {
        bottom: 0;
        opacity: 0;
    }
    to {
        bottom: 30px;
        opacity: 1;
    }
}

@-webkit-keyframes fadeout {
    from {
        bottom: 30px;
        opacity: 1;
    }
    to {
        bottom: 0;
        opacity: 0;
    }
}

@keyframes fadeout {
    from {
        bottom: 30px;
        opacity: 1;
    }
    to {
        bottom: 0;
        opacity: 0;
    }
}
