@import '../../../../constants.less';

#work {
    background-color: @yellow42;

    & > div.container {
        align-items: center;
    }

    p {
        text-align: justify;
    }

    a {
        color: inherit;
        text-decoration: none;
    }
}
