@import "../../constants.less";

#careers {
    #careers-home {
        background-color: @white42;
        justify-content: center;
    }

    #careers-positions {
        background-color: @green42;

        #careers-open-application {
            margin-top: 50px;
        }

        .position {
            position: relative;
            padding: 10px 0;
            border-bottom: @white42 1px solid;

            > img.arrow {
                position: absolute;
                right: 0;
                bottom: 20px;
                height: 24px;
                cursor: pointer;

                &.down {
                    transform: rotate(90deg);
                    transform-origin: center;
                    transition: tranform 0.6s linear;
                }

                &.up {
                    transform: rotate(-90deg);
                    transform-origin: center;
                    transition: tranform 0.6s linear;
                }
            }

            p {
                text-align: left;

                &.position-title {
                    font-size: 32px;
                }
            }

            .info {
                display: flex;
                justify-content: space-between;
                align-items: center;

                > div {
                    display: flex;
                    margin: 0 -20px;

                    > div.position-info {
                        display: flex;
                        flex-direction: column;
                        width: fit-content;
                        margin: 10px 20px;

                        > p {
                            margin: 2px 0;
                        }

                        > p:first-child {
                            font-weight: bold;
                        }
                    }
                }
            }

            .content {
                max-height: 0;
                overflow: hidden;
                padding-bottom: 20px;
                transition: max-height 0.6s ease-in-out;

                &.expanded {
                    max-height: 800px;
                }

                .position-section {
                    margin: 24px 0;

                    > p {
                        margin: 2px 0;
                    }
                }
            }
        }
    }

    #careers-generic {
        background-color: @yellow42;

        ul {
            font-size: 24px;

            li {
                margin: 8px 0;
            }
        }
    }
}

@media screen and (max-width: @mobile-design) {
    #careers {
        > #careers-positions .position {
            > .info {
                align-items: flex-end;
                padding: 20px 0;

                > div {
                    flex-direction: column;
                }
            }

            > .content.expanded {
                max-height: 1400px;
            }
        }

        > #careers-generic {
            ul {
                font-size: 18px;
            }
        }
    }
}
