@import "../../constants.less";

#menu-container {
    background-color: @black42;

    #options-container {
        display: flex;
        padding-top: 20vh;
        flex-wrap: wrap;

        .column {
            width: 50%;

            a {
                text-decoration: none;
                color: white;
            }

            h1 {
                margin: 0 0 10px 0;
                cursor: pointer;

                &:hover {
                    text-decoration: underline;
                }
            }
        }
    }

    #social-bar {
        position: absolute;
        bottom: 40px;
        right: 0;
        margin-right: 50px;
        padding-right: calc(50% - 600px);

        a {
            text-decoration: none;
            color: white;
            padding: 15px 0px;
            margin: 0 40px;

            &:hover {
                border-top: 1px solid white;
            }
        }
    }
}

@media screen and (max-width: @mobile-design + 140px) {
    #menu-container {
        justify-content: center;

        h1 {
            font-size: 56px;
        }

        #options-container {
            justify-content: flex-end;
            padding-top: 8vh;

            .column {
                width: 90%;
            }
        }
    }
}

@media screen and (max-width: @mobile-design) {
    #menu-container {
        padding-top: 0;

        #social-bar {
            left: 0%;
            bottom: 20px;
            width: 100%;
            display: flex;
            align-items: flex-end;
            justify-content: space-around;

            a {
                margin: 0px;
            }
        }
    }
}

@media screen and (max-width: @mobile-design) and (orientation: landscape) {
    #menu-container {
        #options-container {
            padding-top: 0;
            justify-content: center;

            .column {
                width: 200px;
            }
        }
    }
}
